import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import 'animate.css'

export default function Layout({ children }) {
    return (
        <div className="bg-dark">
            <Navbar />
            <div>
                {children}
            </div>
            <Footer />
        </div>
    )
}
