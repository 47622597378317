import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image';
import '../styles/_navbar.scss';
import navdata from '../data/component-data/navlink.json';

export default function Navbar() {

    const data = useStaticQuery(graphql`
    {
        site{
            siteMetadata {
                title,
                email,
                currentYear,
                contact
            }
        }
    }
    `)

    const { title, email, currentYear, contact } = data.site.siteMetadata

    return (
        <div>
            <div className="text-muted dark-contrast align-items-center p-2">
                <div className="d-flex flex-row">
                    <div>
                        <small>
                            <span className="iconify" data-icon="mdi:email-outline" data-inline="false"></span> {email} | <span className="iconify" data-icon="mdi:phone-outline" data-inline="false"></span> {contact}
                        </small>
                    </div>
                    <div className="ms-auto">
                        <small>
                            <span role="img" aria-labelledby="origin-country">&copy; {currentYear} | {title} &trade;</span>
                        </small>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark w-100 top-0 shadow mb-3">
                <div className="container-fluid">
                    <Link className="navbar-brand lh-sm" to="/">
                        <div className="row">
                            <div className="col">
                                <StaticImage src="../images/TPI_Logo.png" layout="fixed" height={50} alt="brand-logo"></StaticImage>
                            </div>
                            <div className="col">
                                <span className="brand-color">Techno-park</span> Inc.
                                <div>
                                    <small className="fw-light text-muted" style={{ fontSize: "0.8rem" }}>
                                        Committed
                                        to
                                        Deliver
                                    </small>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            {navdata.links.map(menuItem => (
                                menuItem.title === 'Store' ? (
                                    <li className="nav-item" key={menuItem.key}>
                                        <a className="nav-link" aria-current="page" target={menuItem.target} href={menuItem.slug}>{menuItem.title}</a>
                                    </li>
                                ) : (
                                    <li className="nav-item" key={menuItem.key}>
                                        <Link className="nav-link" aria-current="page" target={menuItem.target} to={menuItem.slug}>{menuItem.title}</Link>
                                    </li>
                                )
                            ))}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}
