import React from 'react'
import '../styles/_footer.scss';
import { Link, graphql, useStaticQuery } from 'gatsby';
import footerlinks from '../data/component-data/navlink.json';

export default function Footer() {
    const data = useStaticQuery(graphql`
    {
        site{
            siteMetadata {
                title,
                currentYear,
                contact,
                email
            }
        }
    }
    `)

    const { title, currentYear, contact, email } = data.site.siteMetadata
    return (
        <div>
            <footer className="bg-dark mt-3 shadow">
                <ul className="container p-5 text-white">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="dark-contrast-border p-4">
                                <h5 className="fw-bold"><span className="brand-color">Techno-park</span> Inc.</h5>
                                <p>
                                    <small className="fw-light text-muted">
                                        Committed<br></br>
                                        to<br></br>
                                        Deliver<br></br>
                                    </small>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 text-end">
                            <h5 className="fw-bold mb-4">Links</h5>
                            <li className="mb-2 text-muted" key="home">
                                <Link to='/' className="text-muted fw-light text-decoration-none">Techno-park Inc.</Link>
                            </li>
                            {footerlinks.links.map(footerItem => (
                                footerItem.title === "Store" ? (
                                    <li className="mb-2 text-muted" key={footerItem.title}>
                                        <a href={footerItem.slug} target={footerItem.target} className="text-muted fw-light text-decoration-none">{footerItem.title}</a>
                                    </li>
                                ) : (
                                    <li className="mb-2 text-muted" key={footerItem.title}>
                                        <Link to={footerItem.slug} target={footerItem.target} className="text-muted fw-light text-decoration-none">{footerItem.title}</Link>
                                    </li>
                                )
                            ))}
                        </div>
                        <div className="col-lg-4 text-end">
                            <h5 className="fw-bold mb-4">Address</h5>
                            <p className="text-justify">
                                <small className="fw-light text-muted lh-lg">
                                    Block C, Plot No. 27 & 28,<br />
                                    New Shivalik Nagar, Near BSP Office,<br />
                                    Haridwar ( 249403 ), Uttarakhand,<br />
                                    India &#127470;&#127475;<br />
                                </small>
                            </p>
                            <h5 className="fw-bold mb-4 mt-4">Contact</h5>
                            <p className="text-justify">
                                <small className="fw-light text-muted lh-lg">
                                    <span className="iconify" data-icon="mdi:email-outline" data-inline="false"></span> { email }<br></br>
                                    <span className="iconify" data-icon="mdi:phone-outline" data-inline="false"></span> { contact }
                                </small>
                            </p>
                        </div>
                    </div>
                </ul>
                <div className="text-muted p-3 dark-contrast">
                    <div className="d-flex flex-row">
                        <div className="text-left">
                            <small>
                                &copy; {currentYear} | {title}
                            </small>
                        </div>
                        <div className="ms-auto">
                            <small>
                                <span role="img" aria-labelledby="origin-country">India &#127470;&#127475;</span>
                            </small>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
